@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BrownStd';
  src: url('../public/fonts/BrownStdRegular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'BrownStd';
  src: url('../public/fonts/BrownStdBold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/OpenSans-Light.ttf');
  font-weight: 300;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  min-height: 100vh;
}

.on-hover:hover .hovered {
  display: block;
}